// © Copyright 2021 Bike Rental Ltd

import { Box, BoxProps } from "grommet";
import React from "react";
import slide_moto from "../../images/slide_moto.jpg";

interface IMainHeader extends BoxProps {
  children?: React.ReactElement;
}

export const MainHeader: React.FC<IMainHeader> = ({ children, ...boxProps }) => (
  <Box direction="row" pad={{ vertical: "250px" }} background={`url(${slide_moto})`} {...boxProps}>
    <Box direction="row" pad={{ vertical: "100px" }} height="xlarge" background="black" fill={true} align="baseline">
      Menu Item ...
    </Box>
    {children ? children : <></>}
  </Box>
);
