// © Copyright 2021 Bike Rental Ltd

import React from "react";
import { Feature } from "./ui-features/Feature";

function App() {
  return <Feature />;
}

export default App;
