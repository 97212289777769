// © Copyright 2021 Bike Rental Ltd

import { Box, Grommet, ResponsiveContext } from "grommet";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Footer, HeaderTop, MainHeader, PopularList, SearchBar } from "./common";

const theme = {
  global: {
    colors: {
      brand: "#228BE6",
      lightGray: "#909090", // "#5c5c5c"
      //   text: {
      //     dark: "neutral-1",
      //     light: "neutral-1"
      //   }
      blue: "#007bff",
      indigo: "#6610f2",
      purple: "#6f42c1",
      pink: "#e83e8c",
      red: "#dc3545",
      orange: "#fd7e14",
      yellow: "#ffc107",
      green: "#28a745",
      teal: "#20c997",
      cyan: "#17a2b8",
      white: "#fff",
      gray: "#6c757d",
      grayDark: "#343a40",
      primary: "#007bff",
      secondary: "#6c757d",
      success: "#28a745",
      info: "#17a2b8",
      warning: "#ffc107",
      danger: "#dc3545",
      light: "#f8f9fa",
      dark: "#343a40"
    },
    font: {
      family: "Roboto",
      size: "18px",
      height: "20px"
    }
  }
};

export const Feature: React.FC = () => {
  return (
    <Router>
      <Grommet theme={theme} themeMode="dark" full={true}>
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box fill={true}>
              <HeaderTop size={size} />
              <MainHeader />

              <SearchBar>
                <Box>SearchBar...</Box>
              </SearchBar>
              <PopularList>
                <Box>PopularList...</Box>
              </PopularList>
              <Footer>
                <Box>Footer...</Box>
              </Footer>
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </Grommet>
      {/* <div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
        </ul>

        <hr />

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
        </Switch>
      </div> */}
    </Router>
  );
};

// function Home() {
//   return (
//     <div>
//       <h2>Home</h2>
//     </div>
//   );
// }

// function About() {
//   return (
//     <div>
//       <h2>About</h2>
//     </div>
//   );
// }

// function Dashboard() {
//   return (
//     <div>
//       <h2>Dashboard</h2>
//     </div>
//   );
// }
