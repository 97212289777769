// © Copyright 2021 Bike Rental Ltd

import { Box, BoxProps, Text } from "grommet";
import { FacebookOption, GooglePlus, LinkedinOption, MailOption, PhoneVertical, Twitter, Youtube } from "grommet-icons";
import React from "react";

interface IHeaderTop extends BoxProps {
  children?: React.ReactElement;
  size?: string;
}

export const HeaderTop: React.FC<IHeaderTop> = ({ children, size, ...boxProps }) => (
  <Box
    tag="header"
    direction="row"
    align="center"
    justify="between"
    background="#ffffff"
    pad={{ left: "large", right: "large", vertical: "30px" }}
    elevation="medium"
    {...boxProps}
  >
    {children ? (
      children
    ) : (
      <>
        {size === "small" ? (
          <></>
        ) : (
          <Box direction="row" gap="medium">
            <Box direction="row" gap="xsmall">
              <MailOption size="20px" color="gray" />
              <Text size={"small"} color="gray">{`bikerental@slgbikerental.com`}</Text>
            </Box>
            <Box direction="row" gap="xsmall">
              <PhoneVertical size="20px" color="gray" />
              <Text size={"small"} color="gray">{`033-256915`}</Text>
            </Box>
          </Box>
        )}

        <Box direction="row" gap="medium">
          <FacebookOption size="16px" color="gray" />
          <Twitter size="16px" color="gray" />
          <GooglePlus size="16px" color="gray" />
          <LinkedinOption size="16px" color="gray" />
          <Youtube size="16px" color="gray" />
        </Box>
      </>
    )}
  </Box>
);
