// © Copyright 2021 Bike Rental Ltd

import { Box, BoxProps } from "grommet";
import React from "react";

interface ISearchBar extends BoxProps {
  children: React.ReactElement;
}

export const SearchBar: React.FC<ISearchBar> = ({ children, ...boxProps }) => (
  <Box
    tag="header"
    direction="row"
    align="center"
    justify="between"
    background="#ffffff"
    pad={{ left: "medium", right: "small", vertical: "100px" }}
    elevation="medium"
    {...boxProps}
  >
    {children}
  </Box>
);
